import React from 'react';
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

const VideoCarousel = ({bg, id, data, leftAlignText, navRef, sectionColor, width}) => {
  const [videos, setVideos] = React.useState([]);
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    const extractYouTubeVideoIDs = (htmlContent) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      const youtubeUrlPattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]+)/g;      
      const videoObjects = []

      const extractVideoID = (url) => {
        const matches = url.match(youtubeUrlPattern);
        if (matches) {
          for (const match of matches) {
            const idMatch = /[\w-]+$/.exec(match);
            if (idMatch) {
              return idMatch[0];
            }
          }
        }
        return null;
      }

      const tags = doc.querySelectorAll('a[href], iframe[src]');

      tags.forEach(tag => {
        const url = tag.getAttribute('href') || tag.getAttribute('src');
        const title = tag.getAttribute('title') || tag.getAttribute('title');
        const videoID = extractVideoID(url);
        if (videoID) {
          videoObjects.push({id: videoID, title});
        }
      });

      return videoObjects;
    }

    const videosCollection = extractYouTubeVideoIDs(data.content.rendered);

    setVideos(videosCollection);
  }, [data]);

  let sectionClasses = 'section section-full centered-section fade-in ';
  const sectionColorClass = `section-${sectionColor}`;
  sectionClasses = sectionClasses + sectionColorClass;

  let callToActionButton = null;
  if (id === 'reflections-section') {
    callToActionButton = (
      <a className="call-to-action" href="https://found.ee/louiscatoreflections" target="_blank" rel="noreferrer">{"Stream Reflections Now"}</a>
    );
  }

  let bgImage = '';
  if (width > 1016) {
    bgImage = bg;
  }

  return (
    <div id={id} className={sectionClasses} ref={navRef} style={{backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'top' }}>
      {data && <h2 dangerouslySetInnerHTML={{ __html: data.title.rendered }} />}
      <Videos videos={videos} viewportWidth={viewportWidth} />
      {callToActionButton}
    </div>
  );
}

const Videos = ({ viewportWidth, videos }) => {
  const scrollContainerRef = React.useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: viewportWidth * -1, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: viewportWidth, behavior: 'smooth' });
    }
  };

  return (
    <div className="section-full w-screen relative">
      <div className="absolute w-24 inset-y-0 left-0 z-10 flex items-center justify-center">
        <button
          className="transition-opacity p-2 bg-gray-700 text-white rounded-full opacity-80 hover:opacity-100"
          onClick={scrollLeft}
        >
          <PiCaretLeftBold size="3rem" color="#c9b354" />
        </button>
      </div>
      <div
        className="flex overflow-x-auto space-x-0 scrollbar-hide"
        ref={scrollContainerRef}
      >
        {videos.map((v, index) => (
          <div key={index} className="flex-shrink-0 sm:w-1/2 md:w-screen">
            <iframe
              src={`https://www.youtube.com/embed/${v.id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-screen lg:h-screen aspect-video"
              title={v.title}
            ></iframe>
          </div>
        ))}
      </div>
      <div className="absolute w-24 inset-y-0 right-0 z-10 flex items-center justify-center">
        <button
          className="transition-opacity p-2 bg-gray-700 text-white rounded-full opacity-80 hover:opacity-100"
          onClick={scrollRight}
        >
          <PiCaretRightBold size="3rem" color="#c9b354" />
        </button>
      </div>
    </div>
  );
};

export default VideoCarousel;
