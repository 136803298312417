import React from 'react';

const Page = ({bg, id, data, leftAlignText, navRef, sectionColor, width}) => {
  let sectionClasses = 'section centered-section fade-in ';
  const sectionColorClass = `section-${sectionColor}`;
  sectionClasses = sectionClasses + sectionColorClass;

  let textClasses = 'text-content';
  if (leftAlignText) {
    textClasses = textClasses + ' text-left';
  }

  let callToActionButton = null;
  if (id === 'reflections-section') {
    callToActionButton = (
      <a className="call-to-action" href="https://found.ee/louiscatoreflections" target="_blank" rel="noreferrer">{"Stream Reflections Now"}</a>
    );
  }

  let bgImage = '';
  if (width > 1016) {
    bgImage = bg;
  }

  return (
    <div id={id} className={sectionClasses} ref={navRef} style={{backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'top' }}>
      <div className={textClasses}>
        {data && <h2 dangerouslySetInnerHTML={{ __html: data.title.rendered }} />}
        {data && <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />}
      </div>
      {callToActionButton}
    </div>
  );
}

export default Page;
